import {createSlice} from '@reduxjs/toolkit'

import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

const initialUser = () => {
    // const item = window.localStorage.getItem('userInfo')
    // return item ? JSON.parse(item) : {}
    return {}
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userInfo: initialUser()
    },
    reducers: {
        handleLogin: (state, action) => {
            state.userInfo = action.payload

            localStorage.setItem('userInfo', JSON.stringify(action.payload))
            // 增加用户信息变化事件 同步触发
            const setItemEvent = new Event('setUserInfo')
            setItemEvent.newValue = action.payload
            window.dispatchEvent(setItemEvent)
        },
        handleLogout: state => {
            state.userInfo = {}
            state[config.storageTokenKeyName] = null

            localStorage.removeItem('userInfo')
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
        }
    }
})

export const {
    handleLogin,
    handleLogout
} = authSlice.actions

export default authSlice.reducer
