export const web_url = "https://bp.neoao.com/index"

const host_mp = "https://mp.api.neoao.com/"
const host_base = "https://ws.api.neoao.com/"

// const host_mp = "http://www.mp.com/"
// const host_base = "http://www.do.com/"

// const host_mp = "http://mp-dev.neoao.com/"
// const host_base = "http://www.do.com/"

const Url = {
//    socket: 'ws://mp-dev.ws.neoao.com',
//    socket: 'ws://192.168.10.1:9501',
    socket: 'wss://mp.ws.neoao.com',
    users: `${host_mp}users`,
    userinfo: `${host_mp}userinfo`,
    infoParams: `${host_mp}info/params`,
    area: `${host_base}area`,
    auth: `${host_mp}auth`,
    info: {
        message: `${host_mp}info/message`
    },
    shop: {
        store: `${host_mp}shop/store`,
        category: `${host_mp}shop/category`,
        goods: `${host_mp}shop/goods`,
        wxacode: `${host_mp}shop/wxacode`,
        result: `${host_mp}shop/result`,
        count: `${host_mp}shop/count`,
        recommend: `${host_mp}shop/recommend`,
        register: `${host_mp}shop/register`,
        payment: `${host_mp}shop/payment`,
        generate: `${host_mp}shop/generate`,
        theme: `${host_mp}shop/theme`,
        printer: `${host_mp}shop/printer`
    },
    material: {
        upload: `${host_mp}material/upload`,
        uploader: `${host_mp}material/uploader`,
        resource: `${host_mp}material/resource`,
        explain: `${host_mp}material/explain`
    },
    deliver: {
        express: `${host_mp}deliver/express`,
        local: `${host_mp}deliver/local`,
        pickup: `${host_mp}deliver/pickup`,
        setting: `${host_mp}deliver/setting`,
        provider: `${host_mp}deliver/provider`,
        cityrush: {
            provider: `${host_mp}deliver/cityrush/provider`
        }
    },
    member: {
        count: `${host_mp}member/count`
    },
    activity: {
        deliverdiscount: `${host_mp}activity/deliverdiscount`,
        codecash: `${host_mp}activity/codecash`,
        vipsetting: `${host_mp}activity/vipsetting`,
        vip: `${host_mp}activity/vip`,
        vipdiscount: `${host_mp}activity/vipdiscount`,
        depositSetting: `${host_mp}activity/depositsetting`,
        deposit: `${host_mp}activity/deposit`
    },
    operate: {
        material: `${host_mp}operate/material`
    },
    order: {
        info: `${host_mp}order/info`,
        verify: `${host_mp}order/verify`,
        handle: `${host_mp}order/handle`,
        count: `${host_mp}order/count`,
        advancecount: `${host_mp}order/advancecount`,
        service: `${host_mp}order/service`,
        deliver: `${host_mp}order/deliver`
    },
    user: {
        personnel: `${host_mp}user/personnel`,
        shop: `${host_mp}user/shop`
    },
    code: {
        batch: `${host_mp}code/batch`,
        count: `${host_mp}code/count`,
        get: `${host_mp}code/get`
    },
    wechat: {
        auth: `${host_mp}wechat/auth`,
        template: `${host_mp}wechat/template`,
        environment: `${host_mp}wechat/environment`
    },
    message: {
        template: `${host_mp}message/template`
    },
    finance: {
        order: `${host_mp}finance/order`,
        vip: `${host_mp}finance/vip`
    }
}

export default Url
