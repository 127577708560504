import {createSlice} from '@reduxjs/toolkit'

const initialPrinter = () => {
    return {
        errorTotal: 0
    }
}

export const printerSlice = createSlice({
    name: 'printer',
    initialState: {
        data: initialPrinter()
    },
    reducers: {
        setPrinter: (state, action) => {
            state['data'] = action.payload
        }
    }
})

export const {setPrinter} = printerSlice.actions

export default printerSlice.reducer