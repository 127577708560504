import {createSlice} from '@reduxjs/toolkit'

const initialRole = () => {
    return {}
}

export const roleSlice = createSlice({
    name: 'role',
    initialState: {
        data: initialRole()
    },
    reducers: {
        setRole: (state, action) => {
            state['data'] = action.payload
        }
    }
})

export const {setRole} = roleSlice.actions

export default roleSlice.reducer