import {createSlice} from '@reduxjs/toolkit'

const initialPrintConfig = () => {
    return {}
}

/**
 * 打印配置
 * @type {Slice<{data: *}, {setPrinter: reducers.setPrinter}, string>}
 */
export const printConfigSlice = createSlice({
    name: 'print_config',
    initialState: {
        data: initialPrintConfig()
    },
    reducers: {
        setPrintConfig: (state, action) => {
            state['data'] = action.payload
        }
    }
})

export const {setPrintConfig} = printConfigSlice.actions

export default printConfigSlice.reducer