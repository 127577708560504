import {createSlice} from '@reduxjs/toolkit'

const initialNavigation = () => {
    return {
        path: null,
        params: null
    }
}

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: initialNavigation(),
    reducers: {
        navigate: (state, action) => {
            state['path'] = action.payload.path
            state['params'] = action.payload.params
        }
    }
})

export const {navigate} = navigationSlice.actions

export default navigationSlice.reducer
