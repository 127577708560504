import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import navigation from "./navigation"
import role from "./role"
import printer from "./printer"
import printConfig from "./printConfig"

const rootReducer = {
    auth,
    navbar,
    layout,
    navigation,
    role,
    printer,
    printConfig
}

export default rootReducer
