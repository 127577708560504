import Url from '../../../services/network/ApiURL'

export default {
  loginEndpoint: Url.users,
  registerEndpoint: Url.users,
  refreshEndpoint: Url.users,
  logoutEndpoint: Url.users,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'refreshToken'
}
