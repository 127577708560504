export default class Storage {

    /**
     * 设置缓存
     * @param key
     * @param value
     * @param expires 过期时间 秒
     */
    static setItem(key, value, expires = false) {
        if (expires) {
            const options = {
                value,
                expires,
                startTime: new Date().getTime()//记录何时将值存入缓存，毫秒级
            }

            localStorage.setItem(key, JSON.stringify(options))
        } else {
            //如果options.expires没有设置，就判断一下value的类型
            const type = Object.prototype.toString.call(value)

            // eslint-disable-next-line eqeqeq
            if (type == '[object Object]' || type == '[object Array]') {
                options.value = JSON.stringify(value)
            }

            localStorage.setItem(key, value)
        }
    }

    /**
     * 获取缓存
     * @param key
     * @returns {boolean|*}
     */
    static getItem(key) {
        let item = localStorage.getItem(key)
        //先将拿到的试着进行json转为对象的形式
        try {
            item = JSON.parse(item)
        } catch (error) {
            item = item
        }

        if (!!item && !!item.startTime) {
            const date = new Date().getTime()
            //何时将值取出减去刚存入的时间，与item.expires比较，如果大于就是过期了，如果小于或等于就还没过期
            if (date - item.startTime > (item.expires * 1000)) {
                //缓存过期，清除缓存，返回false
                localStorage.removeItem(key)
                return false
            } else {
                //缓存未过期，返回值
                return item.value
            }
        } else {
            //如果没有设置失效时间，直接返回值
            return item
        }
    }

    /**
     * 刷新缓存有效期
     * @param key
     */
    static refreshItem(key) {
        let item = localStorage.getItem(key)
        //先将拿到的试着进行json转为对象的形式
        try {
            item = JSON.parse(item)
        } catch (error) {
            item = item
        }

        if (!!item && !!item.startTime) {
            const date = new Date().getTime()
            if (date - item.startTime > (item.expires * 1000)) {
                localStorage.removeItem(key)
                return false
            } else {
                const options = {
                    value: item.value,
                    expires: item.expires,
                    startTime: date
                }
                localStorage.setItem(key, JSON.stringify(options))
                return true
            }
        }
        return false
    }

    /**
     * 删除缓存
     * @param key
     */
    static removeItem(key) {
        localStorage.removeItem(key)
    }

    /**
     * 清除全部缓存
     */
    static clear() {
        localStorage.clear()
    }
}
