import axios from 'axios'
import jwtConfig from "./jwtConfig"
import Storage from "../../../utility/Storage"

export default class JwtService {
    // ** jwtConfig <= Will be used by this service
    jwtConfig = {...jwtConfig}

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = {...this.jwtConfig, ...jwtOverrideConfig}
    }

    onAccessTokenFetched(accessToken) {
        this.subscribers = this.subscribers.filter(callback => callback(accessToken))
    }

    addSubscriber(callback) {
        this.subscribers.push(callback)
    }

    getToken() {
        return Storage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    setToken(value) {
//    this.storage.setItem(this.jwtConfig.storageTokenKeyName, value, 3600)
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
        sessionStorage.setItem('isLogin', '1')
    }

    refreshToken() {
//    this.storage.refreshItem(this.jwtConfig.storageTokenKeyName)
    }

    removeToken() {
//    this.storage.removeItem(this.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
        sessionStorage.removeItem('isLogin')
    }

    login(...args) {
        return axios.post(this.jwtConfig.loginEndpoint, ...args)
    }

    register(...args) {
        return axios.post(this.jwtConfig.registerEndpoint, ...args)
    }

}
